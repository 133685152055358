<template>
  <div class="product-wrap programme-factory">
    <Header is-active="programme" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-factory">
      <!--      <div class="txt-box">-->
      <!--        <h1>智慧工厂</h1>-->
      <!--        <p>SMART FACTORY</p>-->
      <!--      </div>-->
    </div>
    <div class="white-wrap">
      <div class="fz-big align-ct"><h4>智慧工厂是什么</h4></div>
      <div class="txt-box align-ct no-indent pd-5">
        <p>智慧工厂是现代工厂信息化发展的新阶段，在数字化工厂的基础上，</p>
        <p>利用物联网的技术和设备监控技术加强信息管理和服务；</p>
        <p>清楚掌握产销流程、提高生产过程的可控性、减少生产线上人工的干预、即时正确地采集生产线数据，</p>
        <p>以及合理的生产计划编排与生产进度。</p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big align-ct"><h4>行业痛点</h4></div>
      <div class="pain-box">
        <div class="td-item">
          <img src="../../assets/img/programme/factory/td_a.jpg" alt="车间信息孤岛">
          <p>车间信息孤岛</p>
        </div>
        <div class="td-item">
          <img src="../../assets/img/programme/factory/td_b.jpg" alt="运维成本高">
          <p>运维成本高</p>
        </div>
        <div class="td-item">
          <img src="../../assets/img/programme/factory/td_c.jpg" alt="管理粗犷">
          <p>管理粗犷</p>
        </div>
        <div class="td-item">
          <img src="../../assets/img/programme/factory/td_d.jpg" alt="人员意外事故">
          <p>人员意外事故</p>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big align-ct"><h4>云昇方案</h4></div>
        <div class="txt-box align-lt" style="padding-bottom: 1%">
          云昇新控将信息技术与管理现代化有机结合，利用工厂工艺建模技术、数据采集技术、实时数据处理技术、管理和实时大数据融合技术等手段帮助客户构建智慧工厂。为用户提供如显示控制、有线/无线的物联网通信、电源隔离、RFID读写卡等方案，帮助用户实现工厂的平台化管理、设备显控、联网、远程监测等功能，助力中国工业互联网发展。
        </div>
        <div class="auto-img">
          <img class="mt-2" src="../../assets/img/programme/factory/pic_d.jpg" alt="云昇方案">
        </div>

      </div>
      <div class="white-wrap">
        <div class="max-center">
          <div class="fz-big align-ct"><h4>方案场景</h4></div>
          <div class="auto-img">
            <img class="mt-2" src="../../assets/img/programme/factory/pic_g.jpg" alt="云昇方案">
          </div>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big align-ct"><h4>方案优势</h4></div>
      <div class="max-center">
        <div class="customized-box advantage-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-hulian"></i></div>
            <p>设备互联</p>
            <p>实时可视化</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-KPI1"></i></div>
            <p>关键KPI</p>
            <p>性能可见性</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-shuju"></i></div>
            <p>数据有效</p>
            <p>的洞察</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-zhineng"></i></div>
            <p>基于云的</p>
            <p>智能分析</p>
          </div>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <el-row class="max-center pd-5">
        <el-col :xs="24" :sm="16" :md="14" :lg="14" :xl="14">
          <div class="auto-img">
            <img src="../../assets/img/programme/factory/yyal.png" alt="应用案例">
          </div>
        </el-col>
        <el-col :xs="24" :sm="8" :md="10" :lg="10" :xl="10">
          <div class="bs-info" @click="handleGo('/case-c')">
            <div class="fz-big align-lt"><h4>某制造企业数字化工厂</h4></div>
            <p class="align-lt">工厂焊装、涂装、总装车间设备运行状态由人工监控，设备维修处理人工微信等方式报修，设备报警等
              依靠对讲机呼叫，事后进行异常处理数据人工录入，数据真实有效性较差，人工统计，过程复杂，浪费较多。 信息沟通环节未打通，导致生产成本居高不下。项目主要帮助客户建立数字化工厂，解决企业痛点。</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧工厂-方案中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧工厂-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleGo (way) {
      this.$router.push({ path: way })
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.programme-factory {
  .banner-factory {
    background: url("../../assets/img/programme/factory/banner.png") no-repeat center;
    background-size: cover;
    position: relative;

    //.txt-box {
    //  position: absolute;
    //  left: 20%;
    //  top: 30%;
    //  font-family: Microsoft YaHei;
    //
    //  h1 {
    //    font-size: 60px;
    //    font-weight: bold;
    //    color: #1890FF;
    //    line-height: 1;
    //  }
    //
    //  p {
    //    font-size: 30px;
    //    font-weight: 400;
    //    color: #7B7B7C;
    //    line-height: 2;
    //  }
    //}

  }

}
</style>
